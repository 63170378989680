import React from 'react';
import Loadable from 'react-loadable';
import SectionUsage from '../partials/_SectionUsage';
import SectionPlatform from '../partials/_SectionPlatform';
import SectionMicroLabels from '../partials/_SectionMicroLabels';
import SectionMicroPlaceholders from '../partials/_SectionMicroPlaceholders';
import SectionMicroHelpText from '../partials/_SectionMicroHelpText';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import inputPreviewData from '../../../../data/previews/input.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const Input = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Input;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Input"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Forms" tierThree="Input" />
      <V5Notice
        componentName="Input"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-inputs--docs"
      />

      <PageNavigation
        links={[
          'Type',
          'Size',
          'State',
          'Mobile',
          'Usage',
          'Microcopy',
          'Accessibility',
          'Platform',
        ]}
      />

      <Section title="Type">
        <Paragraph>
          We currently have eight input types to choose from, all built to
          collect different information.
        </Paragraph>
        <ComponentPreview name="InputType" previewData={inputPreviewData.types}>
          <Input
            type="text"
            label="Name"
            placeholder="e.g. Jane Smith"
            helpText="Your name helps identify you."
          />
        </ComponentPreview>
      </Section>

      <Section title="Size">
        <Paragraph>An input can be any of the four available sizes.</Paragraph>
        <ComponentPreview
          name="InputType"
          previewData={inputPreviewData.formSize}>
          <Input
            type="text"
            label="Name"
            placeholder="e.g. Jane Smith"
            helpText="Your name helps identify you."
          />
        </ComponentPreview>
        <Paragraph>
          A form’s density (standard, compact) is dictated by the form modifier
          component. Check{' '}
          <Link href="/components/forms/form-modifier/design">
            those guidelines
          </Link>{' '}
          for more detailed recommendations.
        </Paragraph>
      </Section>

      <Section title="State">
        <Paragraph>
          An input’s state depends on the interaction itself or a submission
          error.
        </Paragraph>
        <ComponentPreview
          name="InputState"
          previewData={inputPreviewData.state}>
          <Input
            type="text"
            label="Name"
            defaultValue="Jane Smith"
            helpText="Please include first and last."
          />
        </ComponentPreview>
      </Section>
      <Section title="Mobile">
        <SectionSubhead>Keyboards</SectionSubhead>
        <Paragraph>
          Entering text on a mobile device can use many different keyboards, but
          it’s important to use the correct one for the input being used. Make
          use of input accessory views for special commands when required.
        </Paragraph>
        <SectionSubhead>Pickers</SectionSubhead>
        <Paragraph>
          Some text inputs may easily make use of available OS pickers to select
          items that don’t need to be input by the user character by character.
          Choose the proper picker to make inputting information as effortless
          as possible.
        </Paragraph>
      </Section>
      <SectionUsage hasLabel hasMobile />

      <Section title="Microcopy">
        <SectionMicroLabels imgName="textinput-label" />
        <SectionMicroPlaceholders imgName="textinput-placeholder" />
        <SectionMicroHelpText
          imgNameSample="textinput-helptext"
          imgNameError="textinput-errorhelptext"
        />
      </Section>

      <AccessibilityAuditComponentResultsSection componentName="Forms / Input" />

      <SectionPlatform />
    </PageWithSubNav>
  );
};

export default IndexPage;
